<template>
  <div class="footer-root">
    <div class="footer-wrapper">
      <div class="logo-column">
        <div class="footer-logo-icon"/>
        <div class="footer-phone"><img class="hidden-lg-and-up" src="@/components/CytFooter/assets/phone-icon.svg"/><span>400-800-7107</span></div>
      </div>
      <div class="menu-column">
        <div class="menu-row">
          <a class="menu-header menu" href="/tools">在线评估</a>
          <a class="menu" href="/tools-pinggu">快速评估</a>
          <a class="menu" href="/tools-vin">VIN码精准评估</a>
          <a class="menu" href="/tools-baozhi">保值率查询</a>
          <a class="menu" href="/tools-chexing">车型库查询</a>
          <a class="menu" href="https://chechebj.com/cheshuju.html" target="_blank">更多查询服务</a>
        </div>
        <div class="menu-row">
          <a class="menu-header menu" href="/aboutus">关于我们</a>
          <a class="menu" href="/aboutus">公司介绍</a>
          <a class="menu" href="/aboutus">服务保障</a>
        </div>
        <div class="menu-row">
          <a class="menu-header menu" href="/aboutus">联系我们</a>
          <a class="menu">商务合作：bc@chechebj.com</a>
          <a class="menu">地址：北京市朝阳区东四环中路195号楼9层909-2</a>
        </div>
      </div>
      <div class="qrcode-column">
        <img class="footer-qrcode-icon" src="@/components/CytFooter/assets/footer-qrcode.png" />
        <span class="footer-qrcode-text">车银通公众号</span>
      </div>
    </div>
    <div class="footer-divider" />
    <div class="footer-copyright">
      <p class="copyright">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备14010574号-1</a>  Copyright © 2021 北京车车 版权所有
      </p>
    </div>
  </div>
</template>
<script>
import "./styles/mobile.scss"
import "./styles/pc/pc.scss"

export default {
  name: 'Footer',
  methods: {
    showTips() {
      this.$message('功能建设中 ！');
    }
  }
}
</script>
